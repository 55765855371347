import React, { Component } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/Header'
import Footer from '../components/footer'
import moment from 'moment'
import 'moment/locale/de'
import bookingSuccessful from '../images/booking-successful.svg'
import bookingUnsuccessful from '../images/booking-unsuccessful.svg'
import calendarIcon from "../images/calendar-icon.svg";
import {Link} from "gatsby";
import axios from "axios";
import {URL} from "../utils/utils";



const TitleSection = styled.div`
  margin: 120px 0 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  @media(max-width: 1184px) {
    align-items: flex-start;
    text-align: left;
    margin: 80px auto 24px;
    width: 95%;
  }
`;

const TagSection = styled.div`
  display: flex;
  align-items: center;
`;

const Tag = styled.h1`
  font-size: 32px;
  line-height: 78px;
  margin-top: 1px;

  @media(max-width: 1184px) {
    line-height: 45px;
  }
`;

const LineLeft = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const LineRight = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-left: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const ContactData = styled.div`
  margin: 60px 0;
  text-align: center;
  color: white;

  p {
    font-size: 12px;
  }
  
  strong {
    font-weight: bold;
    color: #fff;
    font-size: 1.2em;
  }
`;

const BookingInfoWrapper = styled.div`
  text-align: center;
  color: #fff;
  
  img {
    height: 32px;
    margin-bottom: 20px;
  }
  
  p {
    line-height: 25px;
    color: #fff;
  }
`;

const HomepageLinkButton = styled.div`
  text-align: center;
  margin-top: 40px;
  
  a {
    border: 1px solid #fff;
    padding: 10px 20px;
    border-radius: 5px;
  }
`;

const SuccessfulInfoWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  
  img {
    margin-bottom: 10px;
  }
  
  p {
    font-size: 15px;
    line-height: 25px;
    color: #fff;
  }
`;

const NewBookingButton = styled.div`
  text-align: center;
  margin-top: 40px;
  
  a {
    padding: 12px 20px;
    font-size: 15px;
    border-radius: 5px;
    background-color: #213fca;
    display: inline-flex;
    align-items: center;
    
    img {
      margin-left: 15px;
      background: #fff;
      padding: 4px;
      border-radius: 5px;
  }
`;



class BookingSummary extends Component {
  state = {
    success: null,
    visitData: null,
  }

  componentDidMount() {
    const token = new URLSearchParams(window.location.search).get('token')
    axios.post(`${URL}/api/bookings/${token}`)
      .then(response => {
        if (response.status === 201) {
          this.setState({
            success: true,
            visitData: response.data
          })
        } else {
          this.setState({success: false})
        }
      })
      .catch(err => {
        this.setState({success: false})
      });
  }

  render() {
    moment.locale('de');

    if (this.state.success === null) {
      return null;
    }

    if (this.state.success) {
      return (
        <>
          <Header />
          <Layout background="linear-gradient(180deg, #0e1c5d, #203bbc)">
            <SEO title="Termin buchen" />

            <TitleSection>
              <TagSection>
                <LineLeft />
                <Tag>Buchung erfolgreich</Tag>
                <LineRight />
              </TagSection>
            </TitleSection>

            <SuccessfulInfoWrapper>
              <img src={bookingSuccessful} alt="icon" />
              <p>Danke für Ihre Bestätigung.</p>
              <p>Wir freuen uns auf Ihren Besuch am {this.state.visitData && `${moment(this.state.visitData.visit_start_date).utc().format('D MMMM YYYY')} at ${moment(this.state.visitData.visit_start_date).utc().format('HH:mm')}-${moment(this.state.visitData.visit_end_date).utc().format('HH:mm')}`}</p>
            </SuccessfulInfoWrapper>

            <HomepageLinkButton>
              <Link to="/">
                Zurück zur Startseite
              </Link>
            </HomepageLinkButton>

            <ContactData>
              <p>Bei Fragen kontaktieren Sie bitte unser Sekretariat:</p>
              <p><strong>+41 44 387 29 29</strong></p>
              <p>Gefeass-chirurge</p>
              <p>Klinik Hirslanden</p>
              <p>Witellikerstrasse 40</p>
              <p>CH-8032 Zürich</p>
            </ContactData>

          </Layout>
          <Footer />
        </>
      )
    } else {
      return (
        <>
          <Header />
          <Layout background="linear-gradient(180deg, #0e1c5d, #203bbc)">
            <SEO title="Termin buchen" />

            <TitleSection>
              <TagSection>
                <LineLeft />
                <Tag>Buchung nicht erfolgreich</Tag>
                <LineRight />
              </TagSection>
            </TitleSection>

            <BookingInfoWrapper>
              <img src={bookingUnsuccessful} alt="icon" />
              <p>Leider können wir Ihre Buchung nicht bestätigen: Ihr Link ist abgelaufen oder wurde bereits aktiviert.</p>
              <p>Bitte tätigen Sie Ihre Reservation erneut.</p>
            </BookingInfoWrapper>

            <NewBookingButton>
              <Link to="/kalender/?doctor=">
                Termin buchen
                <img src={calendarIcon} alt="icon "/>
              </Link>
            </NewBookingButton>


            <ContactData>
              <p>Bei Fragen kontaktieren Sie bitte unser Sekretariat:</p>
              <p><strong>+41 44 387 29 29</strong></p>
              <p>Gefeass-chirurge</p>
              <p>Klinik Hirslanden</p>
              <p>Witellikerstrasse 40</p>
              <p>CH-8032 Zürich</p>
            </ContactData>

          </Layout>
          <Footer />
        </>
      )
    }
  }
}

export default BookingSummary
